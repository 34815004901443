import React from 'react';

const IconGitlab = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="492.50943" height="453.67966" viewBox="0 0 492.50943 453.67966">
    <g fill="none" fill-rule="evenodd">
      <path
        d="M491.58891 259.39833l-27.55867-84.81467L409.41291 6.48633c-2.80934-8.648-15.04533-8.648-17.856 0l-54.61867 168.09733H155.57158l-54.62-168.09733c-2.80933-8.648-15.04533-8.648-17.856 0L28.47825 174.58366.92092 259.39833c-2.514669 7.736.24 16.21066 6.82 20.992l238.51333 173.28933 238.51466-173.28933c6.58-4.78134 9.33333-13.256 6.82-20.992"
        fill="#fc6d26"/>
      <path d="M246.25478 453.67966l90.684-279.096h-181.368z" fill="#e24329"/>
      <path d="M246.25478 453.67912l-90.684-279.09466h-127.092z" fill="#fc6d26"/>
      <path d="M28.47878 174.58406L.92012 259.39873c-2.513336 7.736.24 16.21066 6.82133 20.99066l238.51333 173.28933z"
            fill="#fca326"/>
      <path d="M28.47878 174.58433h127.092L100.95212 6.487c-2.81067-8.64933-15.04667-8.64933-17.856 0z" fill="#e24329"/>
      <path d="M246.25478 453.67912l90.684-279.09466h127.09199z" fill="#fc6d26"/>
      <path d="M464.03064 174.58406l27.55867 84.81467c2.51333 7.736-.24 16.21066-6.82134 20.99066L246.25465 453.67872z"
            fill="#fca326"/>
      <path d="M464.03064 174.58433h-127.092L391.55731 6.487c2.81066-8.64933 15.04666-8.64933 17.856 0z"
            fill="#e24329"/>
    </g>
  </svg>
);

export default IconGitlab;
